import { isBrowser } from '@/helpers/BrowserHelper';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

declare global {
  interface Window {
    INIStdPay: any;
  }
}

interface InicisPcOnceProps {}

export interface InicisInitParams {
  payMethod: 'Card' | 'VBank' | 'HPP';
  goodname: string | undefined;
  buyername: string | undefined;
  buyertel: string | undefined;
  buyeremail: string | undefined;
  price: number;
  mKey: string;
  signature: string;
  oid: string;
  timestamp: string;
  moduleYn: string;
  gender: string;
  missModuleCode: string;
  missUserId: string;
  solicitCode: string;
}

interface InicisFormData extends InicisInitParams {
  acceptmethod: string;
}

export interface InicisPcOnceRef {
  init: (params: InicisInitParams) => void;
}

const HiddenForm = styled.form`
  display: none;
`;

/**
 * 이니시스 일시 결제 (PC)
 */
const InicisPcOnceForm = forwardRef<InicisPcOnceRef, InicisPcOnceProps>(
  (props, ref) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [formData, setFormData] = useState<InicisFormData>();

    // 결제 시작
    const start = useCallback((params: InicisInitParams) => {
      // acceptmethod 설정
      let acceptmethod = ``;
      if (params.payMethod === `Card`) {
        acceptmethod = `below1000`;
      } else if (params.payMethod === `HPP`) {
        acceptmethod = `HPP(1)`;
      }
      // FormData 세팅
      setFormData({
        ...params,
        acceptmethod,
      });

      formRef.current?.submit();
    }, []);

    // ref로 넘길 함수
    useImperativeHandle(
      ref,
      () => ({
        init: start,
      }),
      [start],
    );

    if (!isBrowser) {
      return null;
    }

    return (
      <>
        {createPortal(
          <HiddenForm
            ref={formRef}
            action={`${process.env.BACKEND_URL}/unicef/api/fo/common/payment/inicisPcForm`}
            method="post"
          >
            <input
              type="hidden"
              name="goodname"
              value={formData?.goodname}
              readOnly
            />
            <input
              type="hidden"
              name="buyername"
              value={formData?.buyername}
              readOnly
            />
            <input
              type="hidden"
              name="buyertel"
              value={formData?.buyertel}
              readOnly
            />
            <input
              type="hidden"
              name="buyeremail"
              value={formData?.buyeremail}
              readOnly
            />
            <input
              type="hidden"
              name="price"
              value={formData?.price}
              readOnly
            />
            <input
              type="hidden"
              name="mid"
              value={process.env.INICIS_PAYMENT_MID}
            />
            <input
              type="hidden"
              name="gopaymethod"
              value={formData?.payMethod}
            />
            <input type="hidden" name="mkey" value={formData?.mKey} readOnly />
            <input
              type="hidden"
              name="signature"
              value={formData?.signature}
              readOnly
            />
            <input type="hidden" name="oid" value={formData?.oid} readOnly />
            <input
              type="hidden"
              name="timestamp"
              value={formData?.timestamp}
              readOnly
            />
            <input type="hidden" name="version" value="1.0" />
            <input type="hidden" name="currency" value="WON" />
            <input
              type="hidden"
              name="acceptmethod"
              value={formData?.acceptmethod}
            />
              <input
              type="hidden"
              name="moduleYn"
              value={formData?.moduleYn}
              readOnly
            />
             <input
              type="hidden"
              name="gender"
              value={formData?.gender}
              readOnly
            />
              <input
              type="hidden"
              name="missModuleCode"
              value={formData?.missModuleCode}
              readOnly
            />
             <input
              type="hidden"
              name="missUserId"
              value={formData?.missUserId}
              readOnly
            />
             <input
              type="hidden"
              name="solicitCode"
              value={formData?.solicitCode}
              readOnly
            />
          </HiddenForm>,
          document.body,
        )}
      </>
    );
  },
);

export default InicisPcOnceForm;
