import { isBrowser } from '@/helpers/BrowserHelper';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

const SDK_URL = `https://nsp.pay.naver.com/sdk/js/naverpay.min.js`;

// 네이버페이 객체 옵션
interface NaverPayOptions {
  payType: 'normal' | 'recurrent';
  mode: 'development' | 'production';
  clientId: string;
  chainId: string;
  openType?: 'page' | 'popup';
}

// 네이버페이 일시결제 파라미터
interface NaverPayNormalParams {
  productName: string;
  productCount: number;
  totalPayAmount: number;
  taxScopeAmount: number;
  taxExScopeAmount: number;
  merchantPayKey: string;
  merchantUserKey?: string;
}

// 네이버페이 open 함수 일시결제 옵션
interface NaverPayOpenNormalOptions extends NaverPayNormalParams {
  returnUrl: string;
}

// 네이버페이 open 함수 정기결제 옵션
interface NaverPayOpenRecurrentOptions {
  actionType: 'NEW' | 'CHANGE';
  targetRecurrentId?: string;
  productCode: string;
  productName: string;
  totalPayAmount: number;
  returnUrl: string;
}

declare global {
  interface Window {
    Naver: {
      Pay: {
        create: (
          options: NaverPayOptions,
        ) => {
          open: (
            options:
              | NaverPayOpenRecurrentOptions
              | NaverPayOpenNormalOptions
              | string,
          ) => void;
        };
      };
    };
  }
}

interface NaverPayResult {
  resultCode: string;
}

interface NaverPayProps {}

export interface NaverPayRef {
  // 일시결제
  payNormal: (params: NaverPayNormalParams) => void;
  // 정기결제1
  payRecurrent: (reserveId: NaverPayOpenRecurrentOptions) => void;
}

/**
 * 네이버페이
 */
const NaverPayForm = forwardRef<NaverPayRef, NaverPayProps>((props, ref) => {
  // 일시결제
  const startNormal = useCallback((params: NaverPayNormalParams) => {
    window.Naver.Pay.create({
      payType: `normal`,
      mode: process.env.NAVER_PAY_MODE as any,
      clientId: process.env.NAVER_PAY_CLIENT_ID || ``,
      chainId: process.env.NAVER_PAY_ONCE_CHAIN_ID || ``,
    }).open({
      ...params,
      returnUrl: `${process.env.BACKEND_URL}/unicef/api/fo/common/payment/naverReturn`,
    });
  }, []);

  // 정기결제
  const startRecurrent = useCallback<NaverPayRef['payRecurrent']>(
    (reserveId) => {
      window.Naver.Pay.create({
        payType: `recurrent`,
        mode: process.env.NAVER_PAY_MODE as any,
        clientId: process.env.NAVER_PAY_CLIENT_ID || ``,
        chainId: process.env.NAVER_PAY_REGULAR_CHAIN_ID || ``,
      }).open(reserveId);
    },
    [],
  );

  // 스크립트 로드
  const loadScript = useCallback(
    (mode: 'normal' | 'recurrent', data?: any) => {
      const callback =
        mode === `normal`
          ? () => startNormal(data)
          : () => startRecurrent(data);
      if (document.querySelectorAll(`#naverpay-sdk`).length === 0) {
        const script = document.createElement(`script`);
        script.id = `naverpay-sdk`;
        script.src = SDK_URL;
        script.onload = callback;
        document.head.appendChild(script);
      } else {
        // script가 이미 로드된 경우, callback만 실행
        callback();
      }
    },
    [startNormal, startRecurrent],
  );

  // ref로 넘길 함수
  useImperativeHandle(
    ref,
    () => ({
      payNormal: (params) => loadScript(`normal`, params),
      payRecurrent: (reservedId) => loadScript(`recurrent`, reservedId),
    }),
    [loadScript],
  );

  if (!isBrowser) {
    return null;
  }

  return <></>;
});

export default NaverPayForm;
