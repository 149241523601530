import { isBrowser } from '@/helpers/BrowserHelper';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

interface MobiliansOnceProps {}

export interface MobiliansInitParams {
  goodname: string | undefined;
  price: number;
  oid: string;
}

export interface MobiliansOnceRef {
  init: (params: MobiliansInitParams) => void;
}

const HiddenForm = styled.form`
  display: none;
`;

/**
 * 모빌리언스 일시 결제 (PC)
 */
const MobiliansOnceForm = forwardRef<MobiliansOnceRef, MobiliansOnceProps>(
  (props, ref) => {
    const formRef = useRef<HTMLFormElement>(null);

    // 결제 시작
    const start = useCallback((params: MobiliansInitParams) => {
      if (!formRef.current) {
        return;
      }
      formRef.current.goodname.value = params.goodname;
      formRef.current.price.value = params.price;
      formRef.current.oid.value = params.oid;
      formRef.current.submit();
    }, []);

    // ref로 넘길 함수
    useImperativeHandle(
      ref,
      () => ({
        init: start,
      }),
      [start],
    );

    if (!isBrowser) {
      return null;
    }

    return (
      <>
        {createPortal(
          <HiddenForm
            ref={formRef}
            action={`${process.env.BACKEND_URL}/unicef/api/fo/common/payment/kgPaymentForm`}
            method="post"
          >
            <input type="hidden" name="goodname" readOnly />
            <input type="hidden" name="price" readOnly />
            <input type="hidden" name="oid" readOnly />
          </HiddenForm>,
          document.body,
        )}
      </>
    );
  },
);

export default MobiliansOnceForm;
