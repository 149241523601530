import { isBrowser } from '@/helpers/BrowserHelper';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

declare global {
  interface Window {
    INIStdPay: any;
  }
}
interface InicisMobileOnceProps {}

interface InicisMobileInitParams {
  payMethod: 'CARD' | 'VBANK' | 'MOBILE';
  goods: string | undefined;
  uname: string | undefined;
  amt: number;
  oid: string;
  moduleYn: string;
}

export interface InicisMobileOnceRef {
  init: (params: InicisMobileInitParams) => void;
}

const HiddenForm = styled.form`
  display: none;
`;

/**
 * 이니시스 일시 결제 (모바일)
 */
const InicisMobileOnceForm = forwardRef<
  InicisMobileOnceRef,
  InicisMobileOnceProps
>((props, ref) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<InicisMobileInitParams>();

  // 결제 시작
  const start = useCallback((params: InicisMobileInitParams) => {
    setFormData(params);

    formRef.current?.submit();
  }, []);

  // ref로 넘길 함수
  useImperativeHandle(
    ref,
    () => ({
      init: start,
    }),
    [start],
  );

  if (!isBrowser) {
    return null;
  }

  return (
    <>
      {createPortal(
        <HiddenForm
          ref={formRef}
          action="https://mobile.inicis.com/smart/payment/"
          method="post"
          acceptCharset="euc-kr"
        >
          {/* *************************필수 세팅 부분************************************ */}
          {/* 리턴받는 가맹점 URL 세팅 */}
          <input
            type="text"
            name="P_NEXT_URL"
            value={`${process.env.BACKEND_URL}/unicef/api/fo/common/payment/inicisReturnMobile`}
            readOnly
          />

          {/* 지불수단 선택 (신용카드,계좌이체,가상계좌,휴대폰) */}
          <input
            type="text"
            name="P_INI_PAYMENT"
            value={formData?.payMethod}
            readOnly
          />

          {/* 복합/옵션 파라미터 */}
          <input
            type="text"
            name="P_RESERVED"
            value="twotrs_isp=Y&block_isp=Y&twotrs_isp_noti=N"
            readOnly
          />

          <input
            type="text"
            name="P_MID"
            value={process.env.INICIS_PAYMENT_MID}
            readOnly
          />
          <input type="text" name="P_OID" value={formData?.oid} readOnly />
          <input type="text" name="P_GOODS" value={formData?.goods} readOnly />
          <input type="text" name="P_AMT" value={formData?.amt} readOnly />
          <input type="text" name="P_UNAME" value={formData?.uname} readOnly />

          {/* *************************선택 필수 세팅 부분************************************ */}

          {/* 가상계좌 입금 노티 사용시 필수 */}
          <input
            type="text"
            name="P_NOTI_URL"
            value={`${process.env.BACKEND_URL}/inipay/VBankMobile`}
            readOnly
          />
          {/* 휴대폰결제 필수 [1:컨텐츠, 2:실물] */}
          <input type="text" name="P_HPP_METHOD" value="1" readOnly />
          {/* 오류메세지 utf-8 설정 */}
          <input type="text" name="P_CHARSET" value="utf8" readOnly />
        </HiddenForm>,
        document.body,
      )}
    </>
  );
});

export default InicisMobileOnceForm;
